<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.edit_m", {
                m: $t("message.reason_y"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <!-- <div v-if="managers" class="pb-2" :class="mode ? 'text__day2' : 'text__night2'">
            <b>Ma'sul Xodimlar: </b>
            <span v-for="manager in managers" :key="manager.id">{{manager.name}} {{manager.last_name}}, </span>
          </div> -->
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
            <el-col :span="12">
                <el-form-item
                  :label="$t('message.nameGiven')"
                  prop="name"
                  class="label_mini"
                  :class="mode ? 'input__day' : 'input__night'"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.name"
                    v-model="form.name"
                    :placeholder="$t('message.nameGiven')"

                  ></crm-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('message.value_type')"
                prop="reason_type_id"
                class="label_mini"
                :class="mode ? 'input__day' : 'input__night'"
              >
              <el-switch
                v-model="form.penalty_type"
                :class="mode ? '' : 'input__night'"
                class="ml-2 w-100 switch_check_procent"
                active-color="#13ce66"
                inactive-color="#409eff"
                active-text="№"
                inactive-text="%"
                active-value="number"
                inactive-value="procent"
              />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <select-or-hidden-company
              :style="'color:black'"
                  :class="'w-100'"
                  :id="form.branch_id"
                  v-model="form.branch_id"
                ></select-or-hidden-company>
            </el-col>
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"  >
                  {{ form.penalty_type =="number"?$t("message.amount"):$t("message.percent") }}
                </span>
              <el-form-item
                 class="label_mini"
              >
                <crm-input
                  :size="'small'"
                  :inputValue="form.value"
                  :type="'number'"
                  v-model="form.value"
                  :class="mode ? 'input__day' : 'input__night'"
                  :placeholder="form.penalty_type =='number'?$t('message.amount'):$t('message.percent')"
                ></crm-input>
              </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.responsible") }}
                </span>
              <el-form-item
                prop="manager_ids"
                class="label_mini"
              >
              <select-staff
                  :branch_id="form.branch_id"
                  :id="form.manager_ids"
                  v-model="form.manager_ids"
                  :multiple="true"
                ></select-staff>
              </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.type") }}
                </span>
              <el-form-item              
              prop="manager_ids"
              class="label_mini"
            >
              <el-select
                   
                    :placeholder="$t('message.type')"
                    filterable
                    clearable
                    size="small"
                    class="d-block"
                    v-model="form.reason_type"
                    :class="mode ? 'input__day' : 'input__night'"
                       >
                       <el-option
                      :label="$t('message.bonuses')"
                      :value="'bonuses'"
                    ></el-option>
                    <el-option
                      :label="$t('message.sanction')"
                      :value="'sanction'"
                    ></el-option>  
                  </el-select>
              </el-form-item>
              </div>
            </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectReasonType from "@/components/filters/inventory/select-reason-type";
import selectOrHiddenCompany from "@/components/filters/inventory/select-or-hidden-company";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "PenaltyReasonController",
    data() {
        return {
            selected: null,
        };
    },
    created() {},
    components: {
      selectReasonType,
      selectOrHiddenCompany
    },
    computed: {
        ...mapGetters({
            rules: "penaltyReasons/rules",
            model: "penaltyReasons/model",
            columns: "penaltyReasons/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "penaltyReasons/update",
            show: "penaltyReasons/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        dispatch(e) {
          this.$emit("input", e);
          this.selected = e;
        },
    },
};
</script>

